html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #000;
  color: #fff;
}
#test-controls {
  display: none;
}
#controls * {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
#storyAnimatorContainer {
  display: inline-block;
}
#slider {
  height: 12px;
  margin: 8px 10px;
}
button {
  width: 80px;
}
#controls input {
  display: inline;
  padding: 2px;
  margin: 2px;
}
.speaker.mute span:before {
  transform: scale(0.5) translate(-15px, 0) rotate(36deg);
  opacity: 0;
}
.speaker {
  top: 10px;
  left: 15px;
  height: 30px;
  width: 30px;
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.speaker:hover span:before {
  transform: scale(0.8) translate(-3px, 0) rotate(42deg);
}
.speaker span {
  display: block;
  width: 8px;
  height: 8px;
  background: #fff;
  margin: 11px 0 0 2px;
}
.speaker span:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  border-width: 10px 14px 10px 15px;
  left: -13px;
  top: 5px;
}
.speaker span:before {
  transform: rotate(45deg);
  border-radius: 0 50px 0 0;
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border-style: double;
  border-color: #fff;
  border-width: 7px 7px 0 0;
  left: 18px;
  top: 9px;
  transition: all 0.2s ease-out;
}
